export const DropdownItems = [
    {
        title: 'Aktuella/Planerade valpkullar',
        path: '/valpar',
    },
    {
        title: 'Tidigare valpkullar',
        path: '/valpar/tidigare-valpkullar',
    },
    /* {
        title: 'Skötselråd',
        path: '/valpar/skotselrad',
    },
    {
        title: 'Bra övningar',
        path: '/valpar/bra-ovningar',
    } */
];